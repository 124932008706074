const numPhotos = 4;
//This Array keeps track of where we have moved photos
let indexArray = [];

// This array contains the actual image data (Bits)
let imageSrcArray = [];

// // Hide sorter
const showSorterElement = document.getElementById('containerSorter');
showSorterElement.style.display = 'none';

// Set up "hidden" key combination to show sorter
// Control + M on Windows/Linux, Command + M on Mac
document.addEventListener('keydown', function (e) {
  if ((e.ctrlKey || e.metaKey) && e.key === 'm') {
    //const showSorterElement = document.getElementById('containerSorter');
    console.log('showSorterElement=', showSorterElement);
    showSorterElement.style.display = 'flex';

    //Hide save button
    const button = document.getElementById('btnSavePhotosSorter');
    button.disabled = true;
  }
});

// Add another slide to sorter collection
function addPhotoBox(index, photo) {
  // Template for new photo element
  // const newItemHTML = `<div id="div${index}" ondrop="drop(event)"     ondragover="allowDrop(event)">
  //         <img src=${photo} draggable="true"
  //         ondragstart="drag(event)" class="fixed-ratio-img" id="drag${index}" width="190" height=140" />
  //       </div>`;
  // // Add to bottom of groupYear accordian segment
  // let newDiv = document.createElement('div');
  // newDiv.innerHTML = newItemHTML;
  // // Get a reference to an existing element
  // const dropBoxElement = document.getElementById('dropBoxes');
  // dropBoxElement.insertAdjacentHTML('beforeend', newDiv.innerHTML);

  const newDiv = document.createElement('div');
  newDiv.id = `div${index}`;
  newDiv.ondrop = function (event) {
    drop(event);
  };
  newDiv.ondragover = function (event) {
    allowDrop(event);
  };

  const newImg = document.createElement('img');
  newImg.src = photo;
  newImg.draggable = true;
  newImg.ondragstart = function (event) {
    drag(event);
  };
  newImg.classList.add('fixed-ratio-img');
  newImg.id = `drag${index}`;
  newImg.width = 190;
  newImg.height = 140;

  newDiv.appendChild(newImg);
  //document.body.appendChild(newDiv);
  console.log('newDiv=', newDiv);

  // // Get a reference to an existing element
  const dropBoxElement = document.getElementById('dropBoxes');
  console.log('dropBoxElement=', dropBoxElement);

  if (dropBoxElement) {
    dropBoxElement.appendChild(newDiv);
  }
}

function findActiveTripIndex(tripsArray) {
  let index = -1; // Initialize index to -1 as a "not found" indicator

  try {
    const openItem = document.getElementsByClassName('item open');
    if (openItem.length === 0) {
      throw new Error('No open item found');
    }

    const destination = openItem[0].getElementsByClassName('destination');
    if (destination.length === 0) {
      throw new Error('No destination found in open item');
    }

    const activeTripName = destination[0].innerText;
    //console.log('openItem=', openItem);
    //console.log('activeTripName=', activeTripName);

    // Assuming tripsArray is an array of objects that have a 'name' property
    index = tripsArray.findIndex((trip) => trip.name === activeTripName);

    if (index === -1) {
      throw new Error('Trip not found in array');
    }
  } catch (error) {
    console.error(error.message);

    return null;
  }
  return index; // Return the index, or -1 if not found
}

// Load up photo sorter images
document
  .querySelector('.btnLoadPhotosSorter')
  .addEventListener('click', function (e) {
    e.preventDefault();
    console.log('btnLoadPhotos - Clicked');

    // Remove any existing photos from sorter
    const dropBoxElement = document.getElementById('dropBoxes');
    //console.log('dropBoxElement=', dropBoxElement);
    dropBoxElement.innerHTML = '';

    // Need to get name of open trip
    try {
      // array to keep track of photo positions by index
      indexArray = [];
      // Array to hold photo image data
      imageSrcArray = [];

      const activeTripIndex = findActiveTripIndex(window.tripsList);
      const activeTripData = window.tripsList[activeTripIndex];

      // Load the 4 photos we alread loaded onto sorter
      for (item = 0; item < 4; item++) {
        imageSrcArray[
          item
        ] = `data:image/jpeg;base64,${activeTripData.photoBufferLocal[item]}`;

        // Add photo and box to HTML
        addPhotoBox(item, imageSrcArray[item]);
        indexArray.push(item);
      }

      // Beyond the 4th photo, we need to load them
      loadRemaingPhotos(activeTripData);
    } catch (error) {
      alert('You must select a trip first');
    }
  });

// Load rest of images
async function loadRemaingPhotos(trip) {
  const SMALLIMAGERESOLUTION = 300;
  const numPhotos = trip.photoBufferLocal.length;
  for (item = 4; item < numPhotos; item++) {
    await loadBufferImage(trip.photoId[item], trip, item, SMALLIMAGERESOLUTION);
    // Add photo and box to HTML
    imageSrcArray[
      item
    ] = `data:image/jpeg;base64,${trip.photoBufferLocal[item]}`;
    //console.log('imageSrcArray=', imageSrcArray);
    addPhotoBox(item, imageSrcArray[item]);

    // Continue to initialize idexArray
    indexArray.push(item);
  }
}

// Save results of sorted photos
document
  .querySelector('.btnSavePhotosSorter')
  .addEventListener('click', function (e) {
    e.preventDefault();
    console.log('btnSavePhotos - Clicked');

    const indexOriginal = findActiveTripIndex(window.tripsList);
    let originalTrip = window.tripsList[indexOriginal];

    //console.log('indexOriginal=', indexOriginal);
    //console.log('originalTrip=', originalTrip);

    // Create a deep copy if the object has nested arrays or objects
    let sortedTrip = JSON.parse(JSON.stringify(originalTrip));
    //console.log('sortedTrip=', sortedTrip);

    // Go thru trip properties and re-order them.
    for (index = 0; index < indexArray.length; index++) {
      Object.entries(sortedTrip).forEach(([key, value]) => {
        //console.log(`key: ${key}, value: ${value}`);

        // Only process  properties that are not arrays
        if (Array.isArray(value)) {
          //console.log('IsArrayKey=', key);
          // Use bracket notation for dynamic keys
          sortedTrip[key][index] = originalTrip[key][indexArray[index]];
        }
      });
    }
    //console.log('sortedTrip1=', sortedTrip);
    //console.log('sortedTrip=', sortedTrip);

    // Swap in the thip with the sorted data structure
    window.tripsList[indexOriginal] = sortedTrip;

    // RE-Load visable photos in active trip photo container
    // Find the first element with class "item open"
    const openItem = document.querySelector('.item.open');
    //console.log('openItem=', openItem);

    // If we find an item, find all photos within it with class "open"
    if (openItem) {
      // Get the 3 photo image divs and replace their image sources
      const openPhotos = openItem.querySelectorAll('.photo.open');

      for (fotoItem = 0; fotoItem < 3; fotoItem++) {
        const photoElement = openPhotos[fotoItem];
        //console.log('photoElement=', photoElement);

        if (photoElement) {
          // Check if the element exists
          const photoElementAlt = photoElement.alt;
          const photoElementAltIndex = getTmageIndex(photoElementAlt);
          //console.log('photoElementAltIndex=', photoElementAltIndex);

          const testPhoto =
            window.tripsList[indexOriginal].photoBufferLocal[
              photoElementAltIndex
            ];
          //console.log('testPhoto=', testPhoto);
          photoElement.src = `data:image/jpeg;base64,${testPhoto}`;
        }
      }
      // console.log(
      //   'window.tripsList[indexOriginal]=',
      //   window.tripsList[indexOriginal],
      // );
      // Save modifed trip to database
      const tripId = window.tripsList[indexOriginal]._id;
      //console.log('tripeId=', tripId);

      // We need a non-refrenced copy so we can remove the photo buffer data
      const subsetObj = Object.assign({}, window.tripsList[indexOriginal]);
      //Remove photo data as not part of database
      delete subsetObj.photoBufferLocal;

      // We want to save trip with index=0;
      Object.assign(subsetObj, { index: 0 });
      //console.log('subsetObjM=', subsetObj);

      patchTrip(subsetObj, tripId);

      // Re-initialize arrays - ready for further sorting
      const indexLength = indexArray.length;
      indexArray = [];
      imageSrcArray = [];
      for (index = 0; index < indexLength; index++) {
        indexArray[index] = index;
        imageSrcArray[
          index
        ] = `data:image/jpeg;base64,${sortedTrip.photoBufferLocal[index]}`;
      }
      //console.log('indexArray - Save=', indexArray);
      //console.log('imageSrcArray - Save=', imageSrcArray);

      //Hide save button
      const button = document.getElementById('btnSavePhotosSorter');
      button.disabled = true;
    } else {
      console.log('No item with class "item open" found');
    }
  });

// Prevent reload of page on drop
function allowDrop(ev) {
  console.log('allowDrop', ev);
  ev.preventDefault();
}

function drag(ev) {
  console.log('drag', ev);
  ev.dataTransfer.setData('text', ev.target.id);
  //console.log('text=', ev.target.id);
  //console.log("target=", ev.target);
  //console.log("indexArray=", indexArray);

  let parentID = document.getElementById(ev.target.id).parentElement.id;
  //console.log('parentID=', parentID);
  ev.dataTransfer.setData('parent', parentID);
}

function drop(ev) {
  console.log('drop', ev);
  ev.preventDefault();

  let data = ev.dataTransfer.getData('text');
  //console.log('data=', data);

  // Get index for image we are dragging
  const fromIndex = ev.dataTransfer.getData('parent').substring(3);
  //console.log('fromIndex=', fromIndex);

  // Get index of image we are dragging too
  const parentID = document.getElementById(ev.target.id).parentElement.id;
  const tooIndex = parentID.substring(3);
  //console.log('tooIndex=', tooIndex);

  //Remove item we are draging from array
  const draggedIndex = indexArray[fromIndex];
  indexArray.splice(fromIndex, 1);

  //Add back dragged itam index into Array
  indexArray.splice(tooIndex, 0, draggedIndex);
  console.log('indexArray=', indexArray);

  //Load photos into their proper new positions
  reloadPhotos(indexArray);

  //Hide save button
  const button = document.getElementById('btnSavePhotosSorter');
  button.disabled = false;
}

//Load photos int0 their respective divs
function reloadPhotos(aryIndex) {
  aryIndex.forEach(loadImage);
}

function loadImage(item, index) {
  const dragID = 'drag' + index;
  document.getElementById(dragID).src = imageSrcArray[item];
}
